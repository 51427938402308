<template>
  <div>
        <v-dialog
            v-model="dialog"
            persistent
            width="300"
        >
        <v-card
            color="green ligthen-2"
            dark
        >
            <v-card-text>
            Mohon tunggu
            <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
            ></v-progress-linear>
            </v-card-text>
        </v-card>
        </v-dialog>
  </div>
</template>
<style>
</style>
<script>
  export default {
    name: 'dialogloading',
    computed:{
      dialog:{
        get() {
            return this.$store.state.beranda.dialog_loading
                
        },
        set(val) {
              this.$store.commit("beranda/update_dialog_loading",val)
        }
      }
    },
    methods:{
    },
    data: () => ({
      
    }),
  }
</script>
