import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import BottomNav from 'vue-bottom-nav'
import VueGeolocation from 'vue-browser-geolocation'
import axios from 'axios'
import VueMoment from 'vue-moment'
import VueAxios from 'vue-axios'
import VCalendar from 'v-calendar';
import "./plugins/vuetify-mask.js";
import infiniteScroll from 'vue-infinite-scroll'
import * as VueGoogleMaps from 'vue2-google-maps'
import clampy from '@clampy-js/vue-clampy'
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)

Vue.use(infiniteScroll)
Vue.use(clampy)

Vue.use(VueGoogleMaps, {
  load: {
    //key: 'AIzaSyB0d_PQVCMc4lOazIcjCP4Ths9Wv5q5oIc',
    //key:'AIzaSyDHz7vf520UottjpiqGqPvvebuM4lo8G5o',
    key:'AIzaSyCooAll6HsSHcGqgzxO2iKXGwEuyRtvxA4',
    libraries: 'places',
    language:'id'
  }
});

Vue.use(VCalendar, {
  componentPrefix: 'vc'  // Use <vc-calendar /> instead of <v-calendar /> 
});
Vue.use(VueLodash, { name: 'custom' , lodash: lodash })
Vue.use(VueGeolocation)
Vue.use(VueAxios, axios)
Vue.use(require('vue-moment'))
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  BottomNav,
  VueMoment,
  VueGoogleMaps,
  render: h => h(App)
}).$mount('#app')
