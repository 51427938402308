<template>
    <div style="overflow-x:hidden;overflow-y:hidden">
        <v-dialog
            v-model="dialog_detail"
            fullscreen="fullscreen"
            hide-overlay="hide-overlay"
            transition="dialog-bottom-transition">

            <v-card>
                <v-toolbar dark="dark" color="#68a036">
                    <v-btn icon="icon" dark="dark" @click="dialog_detail = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Detail Pemeriksaan</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items></v-toolbar-items>
                </v-toolbar>
                <v-row class="pb-16">
                    <v-col cols="12">
                        <v-card elevation="0" class="mx-auto" :max-width="windowWidth">

                            <v-card-title class="mb-0 pb-0">{{select_test.name}}</v-card-title>

                            <v-card-text>
                                <div v-if="!_.isEmpty(select_test)" class="subtitle-1 mb-2">
                                    {{formatRupiah(select_test.price)}}
                                </div>

                            </v-card-text>
                            <v-divider class="mx-4"></v-divider>

                            <v-card-title >Deskripsi</v-card-title>
                            <v-card-text>

                                <div class="body-1">{{select_test.description}}</div>
                            </v-card-text>

                            <v-divider v-if="select_test.value_requirement !== 'X'" class="mx-4"></v-divider>

                            <v-card-title v-if="select_test.value_requirement !== 'X'">Persyaratan</v-card-title>
                            <v-card-text v-if="select_test.value_requirement !== 'X'">
                                <p  class="body-1 mb-1"
                                    v-if="select_test.value_requirement !== 'X'"
                                    v-for="(requirement,kreq) in select_test.requirement"
                                    :key="kreq">
                                    {{requirement}}
                                </p>
                                    <v-checkbox color="red darken-3" v-model="select_test.value_requirement" value="Y">
                                    <template v-slot:label>
                                        <div class="pl-3">Saya setuju untuk mematuhi semua persyaratan</div>
                                    </template>
                                    </v-checkbox>
                            </v-card-text>

                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
            <v-row class="fixed-bottom" justify="center">
                <v-col cols="12" class="text-center mb-2">
                    <v-card transition="scroll-y-reverse-transition">
                        <v-btn
                            block="block"
                            @click="addTest()"
                            color="red lighten-1"
                            dark="dark"
                            v-if="select_test.value_requirement === 'X' || select_test.value_requirement === 'Y'"
                            class="pa-5">
                            PILIH PEMERIKSAAN
                        </v-btn>
                        <v-btn
                            block="block"
                            color="grey lighten-2"
                            style="color:#0000008a"
                            dark="dark"
                            depressed
                            v-if="!select_test.value_requirement || select_test.value_requirement === 'N'"
                            class="pa-5">
                            PILIH PEMERIKSAAN
                        </v-btn>
                    </v-card>
                </v-col>
            </v-row>
        </v-dialog>
       <v-app-bar
       height="85px"
      dark
      
    
      color="#68a036"
      fixed
      style="overflow-x:hidden"
      class="pa-0 ma-0"
    >

    <v-icon class="mr-2" @click="goBack()">mdi-arrow-left</v-icon>


      <v-toolbar-title class="ml-0 mb-5">
          <v-text-field
            filled
            solo
            @click="goToSearchPacket()"
            rounded
            dense
            light
            color="white"
            class="mt-12"
            label="Cari Paket"

          >
          <template v-slot:append>
              
                <!--<img
                  v-else
                  width="24"
                  height="24"
                  src="https://cdn.vuetifyjs.com/images/logos/v-alt.svg"
                  alt=""
                >-->
                <v-avatar
                color="green"
                size="28"
                justify="right"
                style="margin-right:-18px"
                >
                <v-icon small dark>mdi-magnify</v-icon>
                </v-avatar>
            
            </template>
          </v-text-field>
      </v-toolbar-title>

      <v-spacer class="mr-2"></v-spacer>
         <v-badge
         v-if="selected_tests.length > 0"
        class="mr-4 mt-3"
         @click="openCart"
        color="#272727"
        :content="selected_tests.length"
        overlap
        
        style="border-color:white"
        dark
      >
        <v-icon :class="{ shake: goyang }" @click="openCart" class="mb-3 ">fa-shopping-cart</v-icon>
      </v-badge>

        <v-icon v-if="selected_tests.length == 0" class="mr-4 mt-3 mb-3 ">fa-shopping-cart</v-icon>
       <v-badge
        class="mt-3 mr-2"
        color="#272727"
         @click="openCart"
        :content="cart.length"
        overlap
        v-if="cart.length > 0 "
        style="border-color:white"
        dark
      >
        <v-icon  @click="openCart" class="mb-3 ">fa-user</v-icon>
      </v-badge>
  <v-icon v-if="cart.length == 0 " @click="openCart" class="mt-3 mr-2 mb-3 ">fa-user</v-icon>
    </v-app-bar>
        <v-row class="mb-0" style="overflow-y:auto;padding-bottom:135px;margin-top:80px"  >
            <v-col class="pl-4 pr-4 pt-0 mb-0" cols="12">
                <v-card color="green" dark
                    elevation="2"
                >
                    <v-card-title>
                        {{selected_packet_category.xname}}
                    </v-card-title>
                    <v-card-subtitle>
                        Tersedia {{packets.length}} paket pilihan
                    </v-card-subtitle>
                    
                </v-card>
                
            </v-col>
            <v-col cols="12" class="mb-0 pb-0">
                <v-divider></v-divider>
            </v-col>
            <v-col cols="12" class="mb-2 pb-0">
                <div style="font-size:18px" class="bold-poppins ml-3 ">Listing {{selected_packet_category.xname}}</div>
                <p class="ml-3 text-caption mb-1">-- Setelah selesai silahkan klik keranjang</p>
            </v-col>
            <v-col class="pl-5 pr-5" cols="12">
                    <v-card  
                        v-if="packets && packets.length > 0"
                        v-for="(packet,k_packet) in packets"
                         @click="selectTest(packet)"
                        :key="k_packet"
                       outlined
                       class="mb-2"
                    >
                        <v-card-text class="pt-2">
                            <v-row class="mb-1 pb-0">
                                    <v-col class="pt-0 pb-0" cols="10">
                                        <v-card
                                            class="mt-0 pt-0 d-flex align-center"
                                            flat
                                            
                                        >
                                        <v-avatar class="mr-2">
                                            <v-img
                                            class="mt-0 pa-0 mb-0"
                                            :src="require('../assets/Logo-Paket-Global.png')"
                                            width="56"
                                        ></v-img>
                                        </v-avatar>
                                        <div class="semi-poppins">{{packet.name}}</div>
                                        </v-card>
                                    </v-col>
                                    <v-col class="text-right pb-0" cols="2">
                                                            <v-btn
                                                                style="margin-top:0px;margin-bottom:2px"
                                                                class="pt-0 pb-0"
                                                                elevation="0"
                                                                text
                                                                x-small
                                                                color="#68a036"
                                                                dark
                                                                >
                                                                <v-icon v-if="check_selected_test(packet) == -1" dark>
                                                                    mdi-plus
                                                                </v-icon>
                                                                <v-icon v-if="check_selected_test(packet) !== -1" dark>
                                                                    mdi-minus
                                                                </v-icon>
                                                            </v-btn>
                                                        </v-col>
                               
                            </v-row>
                            <v-row>
                                <v-col class="pt-0 pb-3" cols="12">
                                    <p class="mb-0"><span v-if="parseInt(packet.bruto) !== parseInt(packet.price)" class="text-decoration-line-through">{{getPrice(packet.bruto)}}</span> <span>{{getPrice(packet.price)}}</span></p>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <v-row>
                                <v-col class="pt-3 pb-0" cols="12">
                                    <div class="semi-poppins">Deskripsi</div>
                                    <p class="mb-0 poppins-light">{{packet.description}}</p>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
            </v-col>
        </v-row>
  
                <!--<v-card @click="openCart" color="#EF5350" tile dark class="box-center mb-0">
                    
                        <p class="mb-0 pl-3 pt-2 font-weight-bold pb-0">KERANJANG</p>
                        <v-row class="pa-2 mt-0 pt-0 pl-3">
                            <v-col cols="8" class="mb-0 mt-0 pt-0 pb-0 ">
                                <p v-if="selected_tests.length > 0" class="mb-0 subtitle-1 ">Pemeriksaan yang dipilih :</p>
                                <p v-if="selected_tests.length > 0"  class="subtitle-2 mb-1">{{selected_tests.length}} produk</p>
                                <p v-if="selected_tests.length === 0" class="mb-0 mt-3 body-2 ">Belum ada pemeriksaan yang dipilih</p>
                            </v-col>
                            <v-col class="text-right pr-4" cols="4">
                                <v-btn v-if="cart.length === 0"  class="text-center">
                                    <v-icon >fa-shopping-basket</v-icon>
                                </v-btn>
                                <v-badge
                                    v-if="cart.length > 0"
                                    bordered="bordered"
                                    color="warning"
                                    content=""
                                    overlap="overlap">
                                    <v-btn  class="text-center" color="red darken-3">
                                        <v-icon >fa-shopping-basket</v-icon>
                                    </v-btn>
                                </v-badge>
    
                            </v-col>
                        </v-row>
                
                </v-card>-->
         
    </div>
</template>
<style>

    .box-center {
        position: fixed;
        width:100%;
        left: 50%;
        bottom: -50px;
        transform: translate(-50%, -50%);
        margin: 0 auto;
    }
    .fixed-bottom-bg {
        position: fixed;
        left: 50%;
        width:100%;
        bottom:-120px;
        transform: translate(-50%, -50%);
        margin: 0 auto;
        box-shadow: 0 6px 6px -3px rgba(0, 0, 0, 0.2), 0 10px 14px 1px rgba(0, 0, 0, 0.14), 0 4px 18px 3px rgba(0, 0, 0, 0.12) !important;
    }

    .horizontal-scroll-fajri {
        display: flex;
        width: 100%;
        overflow-x: auto;

    }
    .horizontal-scroll-fajri::-webkit-scrollbar {
        display: none;
    }
   

    @media only screen and (min-device-width : 1024px) {
        .horizontal-scroll-fajri::-webkit-scrollbar {
            display: inline;
        }
        /* Styles */
        /* width */
        ::-webkit-scrollbar {
            height: 4px;
            /* height of horizontal scrollbar ← You're missing this */
            width: 8px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #888;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }

    .agile__dots {
        bottom: 10px;
        flex-direction: column;
        right: 30px;
        position: absolute;
    }

    .agile__dot {
        margin: 5px 0;
    }

    .agile__dot button {
        background-color: transparent;
        border: 1px solid rgb(199, 14, 14);
        cursor: pointer;
        display: block;
        height: 10px;
        font-size: 0;
        line-height: 0;
        margin: 0;
        padding: 0;
        transition-duration: 0.3s;
        width: 10px;
    }
    .agile__dot--current button, .agile__dot:hover button {
        background-color: #fff;
    }

    .slide {
        display: block;
        height: 180px;
        -o-object-fit: cover;
        object-fit: cover;
        width: 100%;
    }

    .pemeriksaan-satuan{
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='368' height='164' preserveAspectRatio='none' viewBox='0 0 368 164'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1090%26quot%3b)' fill='none'%3e%3cpath d='M4 164L168 0L216 0L52 164z' fill='url(%23SvgjsLinearGradient1091)'%3e%3c/path%3e%3cpath d='M145.44 164L309.44 0L451.94 0L287.94 164z' fill='url(%23SvgjsLinearGradient1091)'%3e%3c/path%3e%3cpath d='M350 164L186 0L123 0L287 164z' fill='url(%23SvgjsLinearGradient1092)'%3e%3c/path%3e%3cpath d='M232.56 164L68.56 0L-57.44 0L106.56 164z' fill='url(%23SvgjsLinearGradient1092)'%3e%3c/path%3e%3cpath d='M229.48155331072144 164L368 25.481553310721438L368 164z' fill='url(%23SvgjsLinearGradient1091)'%3e%3c/path%3e%3cpath d='M0 164L138.51844668927856 164L 0 25.481553310721438z' fill='url(%23SvgjsLinearGradient1092)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1090'%3e%3crect width='368' height='164' fill='white'%3e%3c/rect%3e%3c/mask%3e%3clinearGradient x1='0%25' y1='100%25' x2='100%25' y2='0%25' id='SvgjsLinearGradient1091'%3e%3cstop stop-color='rgba(185%2c 25%2c 15%2c 0.2)' offset='0'%3e%3c/stop%3e%3cstop stop-opacity='0' stop-color='rgba(185%2c 25%2c 15%2c 0.2)' offset='0.66'%3e%3c/stop%3e%3c/linearGradient%3e%3clinearGradient x1='100%25' y1='100%25' x2='0%25' y2='0%25' id='SvgjsLinearGradient1092'%3e%3cstop stop-color='rgba(185%2c 25%2c 15%2c 0.2)' offset='0'%3e%3c/stop%3e%3cstop stop-opacity='0' stop-color='rgba(185%2c 25%2c 15%2c 0.2)' offset='0.66'%3e%3c/stop%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e");
    }
	
    .best-seller{
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='502' height='196' preserveAspectRatio='none' viewBox='0 0 412 196'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1085%26quot%3b)' fill='none'%3e%3crect width='412' height='196' x='0' y='0' fill='rgba(255%2c 134%2c 134%2c 1)'%3e%3c/rect%3e%3cpath d='M0%2c96.946C19.513%2c95.679%2c41.046%2c99.973%2c56.841%2c88.446C72.466%2c77.043%2c74.399%2c55.225%2c81.709%2c37.315C88.684%2c20.226%2c99.125%2c4.258%2c98.736%2c-14.196C98.32%2c-33.911%2c91.105%2c-52.94%2c79.468%2c-68.859C67.19%2c-85.655%2c51.04%2c-100.015%2c31.269%2c-106.493C11.186%2c-113.073%2c-10.903%2c-112.038%2c-30.83%2c-104.999C-50.163%2c-98.169%2c-68.754%2c-85.977%2c-78.251%2c-67.805C-87.132%2c-50.81%2c-78.888%2c-30.619%2c-79.712%2c-11.461C-80.434%2c5.319%2c-85.553%2c21.243%2c-82.794%2c37.811C-79.328%2c58.619%2c-78.956%2c83.886%2c-61.79%2c96.147C-44.682%2c108.367%2c-20.98%2c98.308%2c0%2c96.946' fill='%23ff3838'%3e%3c/path%3e%3cpath d='M412 327.019C436.229 328.528 455.341 308.31100000000004 476.227 295.93899999999996 497.977 283.055 525.867 275.786 536.893 253.037 547.955 230.215 536.481 203.725 533.107 178.588 529.697 153.188 530.325 126.903 517.021 104.999 502.351 80.847 482.978 53.15100000000001 454.913 49.852000000000004 426.529 46.514999999999986 408.102 81.293 380.57 88.959 352.398 96.803 317.592 76.127 294.789 94.436 272.36400000000003 112.441 272.151 147.151 272.274 175.91 272.38599999999997 202.07 280.222 227.957 295.452 249.226 309.178 268.394 333.10699999999997 275.158 352.716 288.247 372.761 301.627 387.947 325.52099999999996 412 327.019' fill='%23ffd4d4'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1085'%3e%3crect width='412' height='196' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
    }

    .shake {
        animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        transform: translate3d(0, 0, 0);
    }

    @keyframes shake {
        10%,
        90% {
            transform: translate3d(-1px, 0, 0);
        }

        20%,
        80% {
            transform: translate3d(2px, 0, 0);
        }

        30%,
        50%,
        70% {
            transform: translate3d(-4px, 0, 0);
        }

        40%,
        60% {
            transform: translate3d(4px, 0, 0);
        }
    }

</style>
<script>
   import { VueAgile } from 'vue-agile'
    export default {
        name: 'xlistingpacket',
        components: {
            agile: VueAgile 
        },
        mounted() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            var localstorage = JSON.parse(localStorage.getItem("oneState"))
            this.selected_branch = localstorage.choosebranch.selected_branch
            this.cart = localstorage.test.cart
            this.selected_tests = localstorage.test.selected_tests
            this.selected_packet_category = localstorage.choosebranch.selected_packet_category
            this.$store.commit("beranda/update_dialog_loading", true)
            var packet_category_prm = this.selected_branch
            packet_category_prm.category_packet_id = this.selected_packet_category.id
            this.$store.dispatch("choosebranch/getPacketListing",packet_category_prm)

            if (this.$vuetify.breakpoint.name == 'xs') {
                this.window_width = window.innerWidth
            } else 
                this.window_width = '560px'

        },
        computed: {
            goyang: {
                get() {
                    return this.$store.state.test.goyang

                },
                set(val) {
                    this
                        .$store
                        .commit("test/update_goyang", val)
                }
            },
            packets: {
                get() {
                    return this.$store.state.choosebranch.packets

                },
                set(val) {

                    this
                        .$store
                        .commit("choosebranch/update_packets", val)
                }
            },
            selected_packet: {
                get() {
                    return this.$store.state.choosebranch.selected_packet

                },
                set(val) {

                    this
                        .$store
                        .commit("choosebranch/update_selected_packet", val)
                }
            },
            cart: {
                get() {
                    return this.$store.state.test.cart

                },
                set(val) {

                    this
                        .$store
                        .commit("test/update_cart", val)
                }
            },
            selected_tests: {
                get() {
                    return this.$store.state.test.selected_tests

                },
                set(val) {
                    this
                        .$store
                        .commit("test/update_selected_tests", val)
                }
            },
            select_test: {
                get() {
                    return this.$store.state.test.select_test

                },
                set(val) {
                    this
                        .$store
                        .commit("test/update_select_test", val)
                }
            },
            selected_packet_category: {
                get() {
                    return this.$store.state.choosebranch.selected_packet_category

                },
                set(val) {
                    this
                        .$store
                        .commit("choosebranch/update_selected_packet_category", val)
                }
            },
            packet_category: {
                get() {
                    return this.$store.state.choosebranch.packet_category

                },
                set(val) {
                    this
                        .$store
                        .commit("choosebranch/update_packet_category", val)
                }
            },
            carousel: {
                get() {
                    return this.$store.state.choosebranch.carousel

                },
                set(val) {
                    this
                        .$store
                        .commit("choosebranch/update_carousel", val)
                }
            },
            categories: {
                get() {
                    return this.$store.state.choosebranch.categories

                },
                set(val) {
                    this
                        .$store
                        .commit("choosebranch/update_categories", val)
                }
            },
            populer: {
                get() {
                    return this.$store.state.choosebranch.populer

                },
                set(val) {
                    this
                        .$store
                        .commit("choosebranch/update_populer", val)
                }
            },
            center: {
                get() {
                    return this.$store.state.choosebranch.location

                },
                set(val) {
                    this
                        .$store
                        .commit("choosebranch/update_location", val)
                }
            },
            currentPlace: {
                get() {
                    return this.$store.state.choosebranch.current_place

                },
                set(val) {
                    this
                        .$store
                        .commit("choosebranch/update_current_place", val)
                }
            },
            subcategories: {
                get() {
                    return this.$store.state.choosebranch.subcategories

                },
                set(val) {
                    this
                        .$store
                        .commit("choosebranch/update_subcategories", val)
                }
            },
            selected_branch: {
                get() {
                    return this.$store.state.choosebranch.selected_branch

                },
                set(val) {
                    this
                        .$store
                        .commit("choosebranch/update_selected_branch", val)
                }
            },
            window_width: {
                get() {
                    return this.$store.state.beranda.window_width

                },
                set(val) {
                    this
                        .$store
                        .commit("beranda/update_window_width", val)
                }
            }
        },
        methods: {
            check_selected_test(pop){
                var populers = this.selected_tests
                var idx = _.findIndex(populers, function(o) { return parseInt(o.test_id) === parseInt(pop.test_id) && o.type === pop.type})
                console.log(idx)
                return idx
            },
            openListPacket(packet) {
               var id = packet.id
                var xstate = {}
                xstate.beranda = this.$store.state.beranda
                xstate.order = this.$store.state.order
                xstate.choosebranch = this
                    .$store
                    .state
                    .choosebranch
                    localStorage
                    .setItem('oneState', JSON.stringify(xstate));
               this.$router.push({name:'xlistingtest'})
            },
            goToSearchPacket(){
                this
                        .$store
                        .commit("test/update_search_packet", '')
                var xstate = {}
                xstate.beranda = this.$store.state.beranda
                xstate.order = this.$store.state.order
                xstate.choosebranch = this.$store.state.choosebranch
                xstate.test = this.$store.state.test
                localStorage.setItem('oneState', JSON.stringify(xstate));
               this.$router.push({name:'xlistingallpacket'})
            },
            addTest() {
                var select_test = this.select_test
                var selected_tests = this.selected_tests
                var nat_test_now = select_test.nat_test
                var found_same_nat_test = false
                selected_tests.forEach(function (test) {
                    var nat_tests = test.nat_test
                        nat_tests
                        .forEach(function (nat_test) {
                            var chk_same = _.filter(nat_test_now, function (o) {
                                return parseInt(nat_test) === parseInt(o)
                            })
                            if (chk_same.length > 0) {
                                found_same_nat_test = true
                            }
                        })
                })
                if (found_same_nat_test) {
                    var message = "Anda sudah memilih beberapa pemeriksaan yang terkadung dalam paket, dalam satu order pasien tidak boleh mengandung pemeriksaan yang sama"
                    this.$store.commit("beranda/update_api_error_message",message)
                } else {
                    selected_tests.push(select_test)
                    this.dialog_detail = false
                    this.dialog_success_add = true
                }
            },
            formatRupiah(angka, prefix) {
                var number_string = angka.toString(),
                    sisa = number_string.length % 3,
                    rupiah = number_string.substr(0, sisa),
                    ribuan = number_string
                        .substr(sisa)
                        .match(/\d{3}/gi);

                // tambahkan titik jika yang di input sudah menjadi angka satuan ribuan
                if (ribuan) {
                    let separator = sisa
                        ? '.'
                        : '';
                    rupiah += separator + ribuan.join('.');
                }

                return 'Rp' + rupiah
            },
            selectTest(test) {
                this.select_test = test
              //  console.log(test)
                var idx = _.findIndex(this.selected_tests, function(o) { return o.test_id === test.test_id && o.type === test.type });
                if(idx === -1){
                    var xstate = {}
                    xstate.beranda = this.$store.state.beranda
                    xstate.order = this.$store.state.order
                    xstate.choosebranch = this.$store.state.choosebranch
                    xstate.test = this.$store.state.test
                    localStorage.setItem('oneState', JSON.stringify(xstate));
                    this.$router.push({name:'xdetailproduct'})
                }
                else{
                    this.selected_tests.splice(idx, 1);
                    this.snackbar = {value:true,timeout:2000,text:test.name+" dikeluarkan dari keranjang"}
                }
            },
            openCart() {
                
                var seltest = this.selected_tests.length
                var cart = this.cart.length
                this.$store.commit("beranda/update_before_menu",'xlistingpacket')
                this.$store.commit("beranda/update_last_menu",'xcart')
                if(seltest > 0 || cart > 0){
                    var xuser =  JSON.parse(localStorage.getItem("user"))
                    if(xuser && xuser.token !== ''){
                        var xstate = {}
                        xstate.beranda = this.$store.state.beranda
                        xstate.order = this.$store.state.order
                        xstate.choosebranch = this.$store.state.choosebranch
                        xstate.test = this.$store.state.test
                        localStorage.setItem('oneState', JSON.stringify(xstate));
                        this.$router.push({name:'xcart'})
                    }
                    else{
                        this.$router.push({name:'xlogin'})
                    }
                }
                
                
            },
            gotoSearchTest(){
                this
                    .$store
                    .commit("choosebranch/update_selected_subcategory", [])
                    var xstate = {}
                    xstate.beranda = this.$store.state.beranda
                     
                xstate.order = this.$store.state.order
                xstate.choosebranch = this
                    .$store
                    .state
                    .choosebranch
                    localStorage
                    .setItem('oneState', JSON.stringify(xstate));
                this.$router.push({name:'xlistingtest'})
            },
          getPrice(price){
          var t_price =  'Rp'+price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
          return t_price
         
        },
            setPlace(place) {
                this.currentPlace = place;
            },
            addMarker() {
                if (this.currentPlace) {
                    const marker = {
                        lat: this
                            .currentPlace
                            .geometry
                            .location
                            .lat(),
                        lng: this
                            .currentPlace
                            .geometry
                            .location
                            .lng()
                    };
                    this
                        .markers
                        .push({position: marker});
                    this
                        .places
                        .push(this.currentPlace);
                    this.center = marker;
                    this.currentPlace = null;
                }
            },
            updateCoordinates(location) {
                this.center = {
                    lat: location
                        .latLng
                        .lat(),
                    lng: location
                        .latLng
                        .lng()
                };
            },
            geolocate: function () {
                navigator
                    .geolocation
                    .getCurrentPosition(this.successPosition, this.failurePosition, {
                        enableHighAccuracy: true,
                        timeout: 15000,
                        maximumAge: 0
                    })
            },
            successPosition: function (position) {
                let prm = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                }
                this.center = prm
                this
                    .markers
                    .push({position: prm});
            },
            failurePosition: function (err) {
                console.log('Error Code: ' + err.code + ' Error Message: ' + err.message)
            },
            goBack() {
                this
                    .$router
                    .push({name: 'xselbranch'})
            },
            clickCategory(subcategory) {
                var subcategories = this.$store.state.choosebranch.selected_subcategory
                subcategories.push(subcategory)
                this.$store.commit("choosebranch/update_selected_subcategory",subcategories)
                var xstate = {}
                xstate.beranda = this.$store.state.beranda
                xstate.order = this.$store.state.order
                xstate.choosebranch = this
                    .$store
                    .state
                    .choosebranch
                    localStorage
                    .setItem('oneState', JSON.stringify(xstate));
               this.$router.push({name:'xlistingtest'})
            }
        },
        data: () => ({
             myOptions: {
            navButtons: false,
            
            responsive: [
                {
                    breakpoint: 600,
                    settings: {
                        dots: true
                    }
                },
                
                {
                    breakpoint: 900,
                    settings: {
                        navButtons: true,
                        dots: true,
                        infinite: false
                    }
                }
            ]
        },
            items: [
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
          },
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
          },
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
          },
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',
          },
        ],
            cards: [
                {
                    title: 'Favorite road trips',
                    src: 'https://cdn.vuetifyjs.com/images/cards/docks.jpg',
                    flex: 6
                }, {
                    title: 'Best airlines',
                    src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg',
                    flex: 6
                }
            ],
            model: null,
            markers: [],
            places: [],
            infiniteId: 1,
            dialog_detail: false,
            dialog_payment_methode: false,
            dialog_daterange: false,
            isLogin: false,
            dialog_city: false,
            dialog_error: false,
            msg_error: '',
            reveal: false,
            dialog_branch: false,
            windowHeight: window.innerHeight,
            windowWidth: window.innerWidth,
            selected_city: {},
            selected_categories: [],
            show: false,
            cities: [
                {
                    name: 'Surabaya'
                }, {
                    name: 'Madiun'
                }, {
                    name: 'Salatiga'
                }, {
                    name: 'Magelang'
                }, {
                    name: 'Yogyakarta'
                }, {
                    name: 'Bandung'
                }
            ]
        })
    }
</script>