// 1 => LOADING
// 2 => DONE
// 3 => ERROR

import * as api from "@/api/api.js"
import moment from 'moment';
import router from '@/router'
export default {
    namespaced: true,
    state: {
        tests:[],
        selected_tests:[],
        select_test:{},
        cart:[],
        patients:[],
        selected_patient:[],
        order_natest:[],
        xcart:[],
        xlocalstate:{},
        datevisit:'',
        timevisit:'',
        visittimes:[{'name':'06:00','kuota':10},{'name':'07:00','kuota':10},{'name':'08:00','kuota':10}],
        selected_date_dialog:'',
        selected_time_dialog:{},
        dialog_kunjungan:false,
        is_klinisi:false,
        cart_url_before:'',
        test_purpose:[],
        xerror_msg:'',
        order_total:{},
        payment_methodes:[],
        deliveries:[],
        paywith_point:false,
        points:0,
        xsearch:'',
        snackbar:{value:false,msg:''},
        all_requirements:[],
        search_packet:'',
        goyang:false,
        loading_patients:false
    },
    mutations: {
        update_loading_patients(state, val) {
            state.loading_patients = val
        },
        update_goyang(state, val) {
            state.goyang = val
        },
        update_search_packet(state, val) {
            state.search_packet = val
        },
        update_all_requirements(state, val) {
            state.all_requirements = val
        },
        update_snackbar(state, val) {
            state.snackbar = val
        },
        update_xsearch(state, val) {
            state.xsearch = val
        },
        update_points(state, val) {
            state.points = val
        },
        update_paywith_point(state, val) {
            state.paywith_point = val
        },
        update_deliveries(state, val) {
            state.deliveries = val
        },
        update_payment_methodes(state, val) {
            state.payment_methodes = val
        },
        update_order_total(state, val) {
            state.order_total = val
        },
        update_xerror_msg(state, val) {
            state.xerror_msg = val
        },
        update_tests(state, val) {
            state.tests = val
        },
        update_selected_tests(state, val) {
            state.selected_tests = val
        },
        update_cart(state, val) {
            state.cart = val
        },
        update_patients(state, val) {
            state.patients = val
        },
        update_selected_patient(state, val) {
            state.selected_patient = val
        },
        update_order_natest(state, val) {
            state.order_natest = val
        },
        update_xcart(state, val) {
            state.xcart = val
        },
        update_xlocalstate(state, val) {
            state.xlocalstate = val
        },
        update_datevisit(state, val) {
            state.datevisit = val
        },
        update_timevisit(state, val) {
            state.timevisit = val
        },
        update_visittimes(state, val) {
            state.visittimes = val
        },
        update_selected_date_dialog(state, val) {
            state.selected_date_dialog = val
        },
        update_selected_time_dialog(state, val) {
            state.selected_time_dialog = val
        },
        update_dialog_kunjungan(state, val) {
            state.dialog_kunjungan = val
        },
        update_is_klinisi(state, val) {
            state.is_klinisi = val
        },
        update_cart_url_before(state, val) {
            state.cart_url_before = val
        },
        update_test_purpose(state, val) {
            state.test_purpose = val
        },
        update_cart_url_before(state, val) {
            state.cart_url_before = val
        },
        update_is_klinisi(state, val) {
            state.is_klinisi = val
        },
        update_dialog_kunjungan(state, val) {
            state.dialog_kunjungan = val
        },
        update_selected_date_dialog(state, val) {
            state.selected_date_dialog = val
        },
        update_selected_time_dialog(state, val) {
            state.selected_time_dialog = val
        },
        update_dialog_kunjungan(state, val) {
            state.dialog_kunjungan = val
        },
        update_visittimes(state, val) {
            state.visittimes = val
        },
        update_timevisit(state, val) {
            state.timevisit = val
        },
        update_datevisit(state, val) {
            state.datevisit = val
        },
        update_xlocalstate(state, val) {
            state.xlocalstate = val
        },
        update_order_natest(state, val) {
            state.order_natest = val
        },
        update_selected_patient(state, val) {
            state.selected_patient = val
        },
        update_patients(state, val) {
            state.patients = val
        },
        update_tests(state, val) {
            state.tests = val
        },
        update_select_test(state, val) {
            state.select_test = val
        },
        update_selected_tests(state, val) {
            state.selected_tests = val
        },
        update_cart(state, val) {
            state.cart = val
        },
        update_xcart(state, val) {
            state.xcart = val
        }
    },
    actions: {
        async addFavorite(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               let fn_url = 'test/addFavorite'
               var user = JSON.parse(localStorage.getItem("user"))
               prm.token = user.token
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  if(resp.satus == 'INVALID_TOKEN'){
                    context.commit("beranda/update_api_error_message",resp.message,{root:true})
                    context.dispatch("login/logout",prm,{root:true})
                  } else
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                var snackbar = {value:true,msg:resp.message}
                  context.commit("update_snackbar",snackbar)
                
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },
        async removeFavorite(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               let fn_url = 'test/removeFavorite'
               var user = JSON.parse(localStorage.getItem("user"))
               prm.token = user.token
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  if(resp.satus == 'INVALID_TOKEN'){
                    context.commit("beranda/update_api_error_message",resp.message,{root:true})
                    context.dispatch("login/logout",prm,{root:true})
                  } else
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                var snackbar = {value:true,msg:resp.message}
                  context.commit("update_snackbar",snackbar)
                
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },
        async getListingTest(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
                //context.commit("update_api_status",true)
                var user = JSON.parse(localStorage.getItem("user"))
               prm.token = user.token
               let fn_url = 'test/getListingTest'
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  //context.commit("update_loading_content",false)
                  context.commit("beranda/update_api_status",3,{root:true})
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  //context.commit("update_loading_content",false)
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  //console.log(resp.data)
                  context.commit("update_tests",resp.rows)
                  context.dispatch("getPatients",{})
               }
            } catch(e) {
                //context.commit("update_loading_content",false)
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },
        async getPatients(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                console.log('modules')
                //console.log(prm)
                //context.commit("update_api_status",true)
                var user = JSON.parse(localStorage.getItem("user"))
               prm.token = user.token
               console.log(prm)
               let fn_url = 'test/getPatients'
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  //context.commit("update_loading_content",false)
                  context.commit("beranda/update_api_status",3,{root:true})
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  //context.commit("update_loading_content",false)
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  //console.log(resp.data)
                  context.commit("update_loading_patients",false)
                  context.commit("update_patients",resp.rows)
                  context.commit("order/update_provinces",resp.provinces,{root:true})
                  context.commit("order/update_selected_province",{},{root:true})
                  context.commit("order/update_ids",resp.ids,{root:true})
                  context.commit("order/update_selected_id",{},{root:true})
                  context.commit("order/update_selected_title",{},{root:true})
                  context.commit("order/update_titles",resp.titles,{root:true})
               }
            } catch(e) {
                //context.commit("update_loading_content",false)
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",'',{root:true})
            }
        },
        async savePatientXX(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               let fn_url = 'order/savePatient'
               var user = JSON.parse(localStorage.getItem("user"))
               prm.token = user.token
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  if(resp.satus == 'INVALID_TOKEN'){
                    context.commit("beranda/update_api_error_message",resp.message,{root:true})
                    context.dispatch("login/logout",prm,{root:true})
                  } else
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  context.commit("update_patients",resp.data)
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },
        async savePatient(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               let fn_url = 'order/savePatient'
               var user = JSON.parse(localStorage.getItem("user"))
               prm.token = user.token
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  if(resp.satus == 'INVALID_TOKEN'){
                    context.commit("beranda/update_api_error_message",resp.message,{root:true})
                    context.dispatch("login/logout",prm,{root:true})
                  } else
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  context.commit("update_patients",resp.data)
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },
        async checkout(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               let fn_url = 'test/checkout'
               var user = JSON.parse(localStorage.getItem("user"))
               prm.token = user.token
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  if(resp.satus == 'INVALID_TOKEN'){
                    context.commit("beranda/update_api_error_message",resp.message,{root:true})
                    context.dispatch("login/logout",prm,{root:true})
                  } else
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                    context.commit("test/update_datevisit",'',{root:true})
                    context.commit("test/update_timevisit",{},{root:true})
                    context.commit("test/update_test_purpose",[],{root:true})
                    context.commit("test/update_deliveries",[],{root:true})
                    context.commit("test/update_visittimes",'',{root:true})
                    context.commit("test/update_select_test",{},{root:true})
                    context.commit("test/update_selected_date_dialog",'',{root:true})
                    context.commit("test/update_selected_time_dialog",'',{root:true})
                    context.commit("test/update_cart",[],{root:true})
                    context.commit("beranda/update_dialog_loading",false,{root:true})
                    context.commit("beranda/update_api_status",2,{root:true})
                    context.commit("beranda/update_api_error_message",'',{root:true})
                    router.push({ path: `/payment/id/${resp.data}`})
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },
        async getTestPurpose(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               let fn_url = 'test/getTestPurpose'
               var user = JSON.parse(localStorage.getItem("user"))
               var xlocalstate = JSON.parse(localStorage.getItem("oneState"))
               prm.cart = xlocalstate.test.cart
               prm.token = user.token
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  if(resp.satus == 'INVALID_TOKEN'){
                    context.commit("beranda/update_api_error_message",resp.message,{root:true})
                    context.dispatch("login/logout",prm,{root:true})
                  } else
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  context.commit("update_test_purpose",resp.data)
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },
        async getDataOrderTotal(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               let fn_url = 'test/getOrderTotal'
               var user = JSON.parse(localStorage.getItem("user"))
               prm.token = user.token
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  if(resp.satus == 'INVALID_TOKEN'){
                    context.commit("beranda/update_api_error_message",resp.message,{root:true})
                    context.dispatch("login/logout",prm,{root:true})
                  } else
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  context.commit("update_order_total",resp.data)
           
                  //context.commit("update_dialog_form",false)
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },
        async pay(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               let fn_url = 'test/pay'
               var user = JSON.parse(localStorage.getItem("user"))
               prm.token = user.token
               let resp= await api.getdata(prm,fn_url)
               console.log(resp)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  if(resp.satus == 'INVALID_TOKEN'){
                    context.commit("beranda/update_api_error_message",resp.message,{root:true})
                    context.dispatch("login/logout",prm,{root:true})
                  } else if(resp.status == 'LUNAS'){
                    router.push({ name: 'xlistorder'})
                  } else
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  router.push({ path: `/paymentva/id/${prm.order.order_number}/code/${prm.payment.code}`})
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",'Error Jaringan',{root:true})
            }
        },
        async getOrdersx(context,prm) {
          
                context.commit("beranda/update_api_status",1,{root:true})
                try {
                    //console.log('modules')
                    //console.log(prm)
                   let fn_url = 'test/getOrders'
                   var user = JSON.parse(localStorage.getItem("user"))
                   prm.token = user.token
                   let resp= await api.getdata(prm,fn_url)
                   if (resp.status != "OK") {
                      context.commit("beranda/update_dialog_loading",false,{root:true})
                      context.commit("beranda/update_api_status",3,{root:true})
                      if(resp.satus == 'INVALID_TOKEN'){
                        context.commit("beranda/update_api_error_message",resp.message,{root:true})
                        context.dispatch("login/logout",prm,{root:true})
                      } else
                      context.commit("beranda/update_api_error_message",resp.message,{root:true})
                   } else {
                      context.commit("beranda/update_dialog_loading",false,{root:true})
                      context.commit("beranda/update_api_status",2,{root:true})
                      context.commit("beranda/update_api_error_message",'',{root:true})
                      context.commit("update_orders",resp.data)
                   }
                } catch(e) {
                    context.commit("beranda/update_dialog_loading",false,{root:true})
                    context.commit("beranda/update_api_status",3,{root:true})
                    context.commit("beranda/update_api_error_message",resp.message,{root:true})
                }
            
            
        },
        async getTimesByDate(context,prm) {
            if(prm){
                context.commit("beranda/update_api_status",1,{root:true})
                try {
                    //console.log('modules')
                    //console.log(prm)
                    
                   let fn_url = 'test/getTimesByDate'
                   var user = JSON.parse(localStorage.getItem("user"))
                   prm.token = user.token
                   let resp= await api.getdata(prm,fn_url)
                   if (resp.status != "OK") {
                      context.commit("beranda/update_dialog_loading",false,{root:true})
                      context.commit("beranda/update_api_status",3,{root:true})
                      if(resp.satus == 'INVALID_TOKEN'){
                        context.commit("beranda/update_api_error_message",resp.message,{root:true})
                        context.dispatch("login/logout",prm,{root:true})
                      } else
                      context.commit("beranda/update_api_error_message",resp.message,{root:true})
                   } else {
                      context.commit("beranda/update_dialog_loading",false,{root:true})
                      context.commit("beranda/update_api_status",2,{root:true})
                      context.commit("beranda/update_api_error_message",'',{root:true})
                      var datavisittime = resp.data
                      var datesplit = context.state.datevisit.split('-')
                      var xdatevisit = moment(datesplit[2]+'-'+datesplit[1]+'-'+datesplit[0]).format('YYYY-MM-DD')
                      if(xdatevisit === context.state.selected_date_dialog){
                          console.log('in')
                        datavisittime.forEach((value,idx) => {
                            //console.log(moment(context.state.selected_date_dialog+' '+value.name).format('YYYY-MM-DD hh:mm'))
                            var var_a = moment(context.state.selected_date_dialog+' '+value.name).format('YYYY-MM-DD hh:mm')
                            var var_b = moment(datesplit[2]+'-'+datesplit[1]+'-'+datesplit[0]+' '+context.state.timevisit.name).format('YYYY-MM-DD hh:mm')
                            let xbefore = moment(var_a).isBefore(moment(var_b));

                            //if(xbefore)
                            //datavisittime[idx].kuota = "0"
                        });
                      }

                      console.log('N')
                      context.commit("update_visittimes",datavisittime)
                      context.commit("update_selected_time_dialog",{})
                      context.commit("update_dialog_kunjungan",true)
                   }
                } catch(e) {
                    context.commit("beranda/update_dialog_loading",false,{root:true})
                    context.commit("beranda/update_api_status",3,{root:true})
                    context.commit("beranda/update_api_error_message",'',{root:true})
                }
            }
            
        },
        async getTimesKunjungan(context,prm) {
            if(prm){
                context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               let fn_url = 'test/getTimesKunjungan'
               var user = JSON.parse(localStorage.getItem("user"))
               prm.token = user.token
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  if(resp.satus == 'INVALID_TOKEN'){
                    context.commit("beranda/update_api_error_message",resp.message,{root:true})
                    context.dispatch("login/logout",prm,{root:true})
                  } else
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  context.commit("update_visittimes",resp.data)
                  context.commit("update_selected_time_dialog",{})
                    var visittimes = resp.data
                    //console.log('alo')
                    //console.log(visittimes)
                    var min_visit_key = -1
                    visittimes.forEach(function (time, i) {
                        if(parseInt(time.kuota) > 0 && min_visit_key == -1){
                            //console.log(time)
                            context.commit("update_selected_time_dialog",time)
                            context.commit("update_timevisit",time)
                            
                            min_visit_key = i
                        }
                    })
                  context.commit("update_dialog_kunjungan",true)
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
            }
            
        },
        async getDeliveries(context) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
                var prm = {}
               let fn_url = 'test/getDeliveries'
               var user = JSON.parse(localStorage.getItem("user"))
               prm.token = user.token
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  if(resp.satus == 'INVALID_TOKEN'){
                    context.commit("beranda/update_api_error_message",resp.message,{root:true})
                    context.dispatch("login/logout",prm,{root:true})
                  } else
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  context.commit("update_deliveries",resp.data)
                  //context.commit("update_dialog_kunjungan",true)
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },
        async getTimesKunjunganHS(context,prm) {
            if(prm){
                context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               let fn_url = 'test/getTimesKunjunganHS'
               var user = JSON.parse(localStorage.getItem("user"))
               prm.token = user.token
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  if(resp.satus == 'INVALID_TOKEN'){
                    context.commit("beranda/update_api_error_message",resp.message,{root:true})
                    context.dispatch("login/logout",prm,{root:true})
                  } else
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  context.commit("update_visittimes",resp.data)
                  context.commit("update_selected_time_dialog",{})
                    var visittimes = resp.data
                    //console.log('alo')
                    //console.log(visittimes)
                    var min_visit_key = -1
                    visittimes.forEach(function (time, i) {
                        if(parseInt(time.kuota) > 0 && min_visit_key == -1){
                            //console.log(time)
                            context.commit("update_selected_time_dialog",time)
                            context.commit("update_timevisit",time)
                            
                            min_visit_key = i
                        }
                    })
                  context.commit("update_dialog_kunjungan",true)
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
            }
            
        },
        async getPointsUser(context) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
                var prm = {}
               let fn_url = 'test/getPointsUser'
               var user = JSON.parse(localStorage.getItem("user"))
               prm.token = user.token
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  if(resp.satus == 'INVALID_TOKEN'){
                    context.commit("beranda/update_api_error_message",resp.message,{root:true})
                    context.dispatch("login/logout",prm,{root:true})
                  } else
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  context.commit("update_points",resp.data)
                  //context.commit("update_dialog_kunjungan",true)
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },
    }
}