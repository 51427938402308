<template>
    <div style="overflow-x:hidden;overflow-y:hidden">
        <v-dialog
      v-model="dialog_payment_methode"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card >
        <v-toolbar
          
          flat
          justify="center"
        >
          <v-btn
            icon
            class="text-center"
            @click="dialog_payment_methode = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card class="mx-auto" flat width="360px">
          <v-list
            subheader
          >
            <template v-for="payment in payment_methodes">
              <v-subheader v-if="payment.is_parent === 'Y'">{{payment.name}}</v-subheader>
              <v-list-item @click="thisPayment(payment)" v-if="payment.is_parent === 'N'">
                <v-list-item-avatar v-if="payment.icon && payment.icon !== ''"class="mt-0 pt-0 mb-0 pb-0" tile>
                  <v-img height="64"contain  :src="payment.icon"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>

                  <v-list-item-title>
                    {{payment.name}}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon v-if="selected_payment.id !== payment.id">mdi-checkbox-blank-outline</v-icon>
                  <v-icon v-if="selected_payment.id === payment.id">mdi-checkbox-marked</v-icon>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
        </v-card>
      </v-card>

    </v-dialog>
    <v-app-bar
      dark
      color="#68a036"
      fixed
      elevation="1"
      height="59px"
    >
      <v-btn
                            color="#fffbfb36"
                            small="small"
                            text
                            class="ml-0 pl-0 white--text"
                         >
                            <v-icon>fa-clipboard-list</v-icon>
                        </v-btn>

      <v-toolbar-title>Pesanan</v-toolbar-title>

      <v-spacer></v-spacer>
    
  
    </v-app-bar>
    
    <v-row class="mx-auto no-gutters mt-15 mb-0 pb-0">
        <v-col class="mb-0 pt-2 pb-0" cols="12">
          <v-card class="ma-2 mt-1" elevation="5">
              <v-card-text class="pt-2 pb-2">
                <v-row class="pt-0 pb-0">
                    <v-col class="body-1 pt-0 pb-0" cols="6">
                        Nomor
                    </v-col>
                    <v-col class="body-1 font-weight-bold pt-0 pb-0 text-right pr-4" cols="6">
                        {{order_total.order_number}}
                    </v-col>
                </v-row>
              </v-card-text>
              <v-divider/>
              <v-card-text class="pt-2 pb-3">
                <v-row class="pt-0 pb-0">
                    <v-col class="body-1 pt-0 pb-0" cols="6">
                        Tanggal
                    </v-col>
                    <v-col class="font-weight-bold body-1 pt-0 pb-0 text-right pr-4" cols="6">
                        {{order_total.order_date}}
                    </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card class="ma-2 mt-1" elevation="5">
              <v-card-title class="body-1 mt-2 pt-2 pb-2 font-weight-bold">Metode Pembayaran</v-card-title>
              <v-divider/>
              <v-card-text>
                <!--<v-card :color="paywith_point == false ? '#525050':'#ff5252'" dark class="mb-3"> 
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title class="mono font-weight-bold">{{order_total.text_points}} poin</v-list-item-title>
                      <v-list-item-subtitle>Gunakan poin untuk pembayaran</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action class="mr-0 pr-0" align="right">
                      <v-switch
                          v-model="paywith_point"
                          inset
                        ></v-switch>
                    </v-list-item-action>
                  </v-list-item>
                </v-card>-->
                <v-btn
                  v-if="_.isEmpty(selected_payment) && !hide_payments" @click="goToPaymentMethodes()"
                  
                  color="primary"
                  dark
                >
                  Pilih Metode Pembayaran
                </v-btn>
                <v-list-item class="pl-0 ml-0" v-if="!_.isEmpty(selected_payment) && !hide_payments"  @click="goToPaymentMethodes()">
                    <v-list-item-avatar v-if="selected_payment.icon && selected_payment.icon !== ''" class="mt-0 pt-0 mb-0 pb-0" tile>
                      <v-img height="64" contain  :src="selected_payment.icon"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>{{selected_payment.name}}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action class="mr-0 pr-0" align="right">
                      <v-icon class="text-right mr-0 pr-0">fa-edit</v-icon>
                    </v-list-item-action>
                </v-list-item>
              </v-card-text>
            </v-card>
            <v-card class="ma-2 mt-1 mb-16" elevation="5">
              <v-card-title class="body-1 mt-2 pt-2 pb-2 font-weight-bold">Ringkasan Pemesanan</v-card-title>
              <v-divider/>
              <v-card-text v-if="order_total.patients">
                <v-row  class="ma-2 mb-1 mt-1 mr-0" v-for="(patient,kpatient) in order_total.patients" :key="kpatient">
                    <v-col class="pb-1 pt-1" cols="12">
                        <p class="mb-0 body-2 font-weight-bold">{{patient.patient_name}}</p>
                        <v-row no-gutters>
                            <v-col class="pa-1 pl-0" cols="6">
                                <p class="mb-0 body-2">Total Pemeriksaan</p>
                            </v-col>
                            <v-col class="pa-1 pl-0" cols="6">
                                <p class="mb-0 body-2 text-right">Rp{{patient.total}}</p>
                            </v-col>
                        </v-row>
                        <v-row  v-if="patient.fee_ehac !== '0'" no-gutters>
                            <v-col class="pa-1 pl-0" cols="6">
                                <p class="mb-0 body-2">Biaya eHAC</p>
                            </v-col>
                            <v-col class="pa-1 pl-0" cols="6">
                                <p class="mb-0 body-2 text-right">{{formatRupiah(patient.fee_ehac)}}</p>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row  class="ma-2 mb-1 mt-1 mr-0">
                    <v-col class="pb-1 pt-1" cols="12">
                        <v-row  v-if="order_total.order_hs == 'Y'" no-gutters>
                            <v-col class="pa-1 pl-0" cols="6">
                                <p class="mb-0 body-2">Biaya Home Service</p>
                            </v-col>
                            <v-col class="pa-1 pl-0" cols="6">
                                <p class="mb-0 body-2 text-right">{{formatRupiah(order_total.hs_cost)}}</p>
                            </v-col>
                        </v-row>
                        <v-row  v-if="order_total.order_hs == 'Y' && parseInt(order_total.hs_costminorder) > 0" no-gutters>
                            <v-col class="pa-1 pl-0" cols="6">
                                <p class="mb-0 body-2">Biaya Minimal Transaksi</p>
                            </v-col>
                            <v-col class="pa-1 pl-0" cols="6">
                                <p class="mb-0 body-2 text-right">{{formatRupiah(order_total.hs_costminorder)}}</p>
                            </v-col>
                        </v-row>
                        <v-row  v-if="order_total.order_hs == 'Y'" no-gutters>
                            <v-col class="pa-1 pl-0" cols="6">
                                <p class="mb-0 body-2">Biaya Tambahan</p>
                            </v-col>
                            <v-col class="pa-1 pl-0" cols="6">
                                <p class="mb-0 body-2 text-right">{{formatRupiah(order_total.costother)}}</p>
                            </v-col>
                        </v-row>
                        <v-row  no-gutters>
                            <v-col class="pa-1 pl-0" cols="6">
                                <p class="mb-0 body-2">Biaya Layanan</p>
                            </v-col>
                            <v-col class="pa-1 pl-0" cols="6">
                                <p class="mb-0 body-2 text-right">Rp{{order_total.fee}}</p>
                            </v-col>
                        </v-row>
                        <v-row  no-gutters>
                            <v-col class="pa-1 pl-0" cols="6">
                                <p class="mb-0 body-2 font-weight-black">GRAND TOTAL</p>
                            </v-col>
                            <v-col class="pa-1 pl-0" cols="6">
                                <p class="mb-0 body-2 font-weight-black text-right">{{formatRupiah(order_total.total)}}</p>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row style="width:100%;background:#fff;" class="fixed-bottom-bg-payment" justify="center">
        <v-col cols="12"  class="text-center mb-2">
            <v-card flat >
                <v-row>
                    <v-col class="text-right pt-1" cols="12">
                            <v-btn
                              v-if="(!_.isEmpty(selected_payment) && !hide_payments) || hide_payments"
                                @click="pay()"
                                color="green darken-1"
                                dark
                                block
                            >
                            Bayar sekarang
                            </v-btn>
                            <v-btn
                             v-if="_.isEmpty(selected_payment) && !hide_payments"
                               
                                disabled
                                block
                            >
                            Bayar sekarang
                            </v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
    </v-row>
              </v-card-text>
            </v-card>
            
            
            
        </v-col>
        
    </v-row>  
    </div>
                
</template>
<style>
.box-center {
        position: fixed;
        left: 50%;
        bottom: -50px;
        transform: translate(-50%, -50%);
        margin: 0 auto;
    }
    .fixed-bottom-bg-payment{
        position: fixed;
        left: 50%;
        bottom: -52px;
        transform: translate(-50%, -50%);
        margin: 0 auto;
        box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12) !important;
    }

@media only screen and (min-device-width : 1024px){
/* Styles */
/* width */
::-webkit-scrollbar {
  height: 8px;              /* height of horizontal scrollbar ← You're missing this */
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
}

</style>
<script>
import axios from 'axios';
import InfiniteLoading from 'vue-infinite-loading';

  export default {
    name: 'xpayment',
    components: {
      InfiniteLoading
    },
    mounted(){
      window.scrollTo({ top: 0, behavior: 'smooth' });
      //this.orders = []
      this.$store.commit("beranda/update_dialog_loading",true)
      this.$store.dispatch("test/getDataOrderTotal", this.$route.params)
      this.$store.commit("test/update_cart",[])
      this.$store.commit("choosebranch/update_selected_branch",{})
      var xstate = {}
      xstate.beranda = this.$store.state.beranda
      xstate.order = this.$store.state.order
      xstate.choosebranch = this.$store.state.choosebranch
      xstate.test = this.$store.state.test
      localStorage.setItem('oneState', JSON.stringify(xstate));
      this.selected_payment = {}
      this.paywith_point = false
      this.points = 0

      if(this.$vuetify.breakpoint.name == 'xs'){
        this.window_width = window.innerWidth
      }
      else
        this.window_width = '560px'

    },
    beforeRouteLeave(to, from, next) {
      if(to.name == 'xpaymentva' ||  to.name == 'xlistorder'){
          next()
      }
      else
         next(false)

    },
    computed:{
      points:{
        get() {
            return this.$store.state.test.points
                
        },
        set(val) {
              this.$store.commit("test/update_points",val)
        }
      },
      paywith_point:{
        get() {
            return this.$store.state.test.paywith_point
                
        },
        set(val) {
              this.$store.commit("test/update_paywith_point",val)
              this.changePaymentType()
        }
      },
        payment_methodes(){
     //console.log(this.$store.state.order.order_total.payments)
        return this.$store.state.test.order_total.payments
      },
    order_total:{
        get() {
            return this.$store.state.test.order_total
                
        },
        set(val) {
              this.$store.commit("test/update_order_total",val)
        }
      },
      info_payment(){
        return this.$store.state.order.info_payment
      },
      selected_payment:{
        get() {
            return this.$store.state.order.selected_payment
                
        },
        set(val) {
              this.$store.commit("order/update_selected_payment",val)
        }
      },

      window_width:{
        get() {
            return this.$store.state.beranda.window_width
                
        },
        set(val) {
              this.$store.commit("beranda/update_window_width",val)
        }
      },
    },
    methods:{
      changePaymentType(){
        this.hide_payments = false
        var total = this.order_total.number_total_fee
        var points = this.order_total.points
        if(parseInt(points) > parseInt(total) && this.paywith_point){
          this.hide_payments = true
        }
        console.log(this.hide_payments)
      },
         numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        formatRupiah(angka, prefix) {
                var number_string = angka.toString()
                        .replace(/[^,\d]/g, '')
                        .toString(),
                    sisa = number_string.length % 3,
                    rupiah = number_string.substr(0, sisa),
                    ribuan = number_string
                        .substr(sisa)
                        .match(/\d{3}/gi);

                // tambahkan titik jika yang di input sudah menjadi angka satuan ribuan
                if (ribuan) {
                    let separator = sisa
                        ? '.'
                        : '';
                    rupiah += separator + ribuan.join('.');
                }

                return 'Rp' + rupiah
            },
      openDetail(pay,i){
            this.payment_title = pay.label
            this.payment_steps = pay.steps
            this.sheet_payment  = true
          },
        goBack(){
            this.$router.push({name:'xberanda'})
        },
        goToPaymentMethodes(){
          this.dialog_payment_methode = true
        },
        thisPayment(payment){
          this.selected_payment = payment
          this.dialog_payment_methode = false
          var payment = this.$store.state.order.selected_payment
          var ordertotal = this.order_total
          var number_total = parseInt(ordertotal.number_total)
          var fee_original = parseInt(ordertotal.number_fee_original)
          var ehac_sum = parseInt(ordertotal.number_ehac_fee_sum)
          //console.log(parseInt(payment.pg_id))
          if(parseInt(payment.type_id) == 1){
            ordertotal.number_fee = Math.round(( (1/100) * number_total ) + Math.round(fee_original) + ehac_sum)
            ordertotal.fee = this.numberWithCommas(Math.round(( (1/100) * number_total ) + Math.round(fee_original)))
          }
          else if(parseInt(payment.type_id) == 5){
            ordertotal.number_fee = 0 + ehac_sum 
            ordertotal.fee = this.numberWithCommas(0)
            console.log(ordertotal)
          }
          else{
            ordertotal.number_fee = fee_original + ehac_sum 
            ordertotal.fee = this.numberWithCommas(fee_original)
            console.log(ordertotal)
          }
          console.log(number_total)
          console.log(ordertotal.number_fee)
          console.log(parseInt(ordertotal.discount_total))
          console.log(ordertotal.hs_cost)
          ordertotal.number_total_fee =  number_total + ordertotal.number_fee - parseInt(ordertotal.discount_total) 
          ordertotal.total = this.numberWithCommas(ordertotal.number_total_fee)
          this.order_total = ordertotal
          this.changePaymentType()

        },
        pay(){
          var payment = this.$store.state.order.selected_payment
          var check_form = true
          if(this.paywith_point){
            if((parseInt(this.order_total.points) < parseInt(this.order_total.number_total_fee)) &&  _.isEmpty(payment)){
              check_form = false
            }
          }
          else{
            if(!this.paywith_point &&  _.isEmpty(payment)){
              check_form = false
            }
          }
          if(check_form){
            var ordertotal = this.order_total
            this.$store.commit("beranda/update_dialog_loading",true)
            this.$store.dispatch("test/pay",{order:ordertotal,payment:payment,paywith_point:this.paywith_point})
          }
          else{
              this.$store.commit("beranda/update_api_error_message",'Anda belum menentukan metode pembayaran')
          }
        }
        
    },
    data: () => ({
      hide_payments:false,
      sheet_payment:false,
      paymwnt_title:'',
      payment_steps:[],
      infiniteId: +new Date(),
          masks: {
        input: 'DD-MM-YYYY',
      },
      infiniteId:1,
      dialog_payment_methode:false,
      dialog_daterange:false,
      isLogin:false,
      dialog_city:false,
      dialog_error:false,
      msg_error:'',
      reveal:false,
      dialog_branch:false,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      selected_city: {},
      categories:[{id:1,name:'PCR',active:false},{id:1,name:'Swab Antigen',active:false}],
      selected_categories:[],
      show: false,
      cities: [
          { name: 'Surabaya'},
          { name: 'Madiun'},
          { name: 'Salatiga'},
          { name: 'Magelang'},
          { name: 'Yogyakarta'},
          { name: 'Bandung'},
        ],
    }),
  }
</script>
