<template>
  <v-container class="fill-height pa-0">
      <v-dialog
            v-model="dialog_regional"
            persistent
            scrollable
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
            >
            <!--<v-app-bar
                    color="white"
                    fixed
                    
                    height="90px"
                  >
                  <v-row align="center">
                     <v-col justify="right" class="text-right font-weight-bold red--text" cols="4">
                        <v-img
                          style="float: right;"
                          class="text-right"
                          :lazy-src="require('../assets/pramita_kotak.png')"
                          :src="require('../assets/pramita_kotak.png')"
                          width="100"
                          height="50"
                        ></v-img>
                     </v-col>
                     <v-col>
                       <div style="font-size:22px;color:#e0282c" class="ml-3 poppins-bold">PRAMITA MOBILE</div>
                      </v-col>
                     
                  </v-row>
                  
                    
              
                    
                    
                  
            </v-app-bar>-->
            <v-app-bar
                    color="white"
                    fixed
                    height="90px"
                  >
                  <v-row align="right">
                     <v-col cols="5">
                        <v-img
                          :src="require('../assets/pramita_kotak.png')"
                          aspect-ratio="0.7"
                          contain
                          height="90"
                        ></v-img>
                     </v-col>
                     <v-col class="text-left">
                       <p style="font-size:22px;color:#68a036;line-height: 22px;" class="pt-8 mb-0 pb-0 poppins-bold">Pilih Wilayah</p>
                       
                      </v-col>
                     
                  </v-row>
                  
                    
              
                    
                    
                  
                  </v-app-bar>
            <v-card  class="mx-auto" >
                <v-card-text style="margin-top:100px">
                  
             
                    <v-row  class="mb-2">
                        <v-col cols="12">
                            <v-text-field
                                label="Cari Regional"
                                filled
                                block
                                    rounded
                                    append-icon="mdi-magnify"
                                clearable
                                v-model="search_regional"
                                @keyup="goSearch"
                                hide-details
                            ></v-text-field>
                            <v-progress-linear
                                color="blue"
                                indeterminate
                                reverse
                                v-if="loading"
                            ></v-progress-linear>
                        </v-col>
                        
                    </v-row>
              
                    <v-row>
                        <v-col cols="12" class="pl-6 pr-6">
                          <v-card
                            class="pa-3  text-no-wrap rounded-lg"
                            style="margin-bottom:10px"
                            v-for="(ct, i_ct) in regionals"
                            :key="i_ct"
                        
                            @click="setRegional(ct)"
                          >
                            <v-row class="pa-0 ma-0 mb-2" align="center">
                            <v-col class="pa-1" cols="9">
                                <p style="font-size:18px" class="poppins-bold mb-1">{{ct.name}}</p>
                            </v-col>
                            <v-spacer/>
                            <v-col v-if="ct.id === selected_regional.id"  class="pa-1 text-right">
                            
                                <v-icon :class="{'green--text text--lighten-2':ct.id === selected_regional.id}">fa-check</v-icon>
                            
                            </v-col>
                            </v-row>
                          </v-card>
                        <!--<v-card 
                            v-for="(ct, i_ct) in regionals"
                            :key="i_ct"
                        
                            @click="setRegional(ct)"
                            hover
                            rounded-pill
                            rounded
                            class="pa-3 "
                        >
                            <v-row class="pa-0 ma-0" align="center">
                            <v-col class="pa-1" cols="9">
                                <p class="font-weight-bold body-1 mb-1">{{ct.name}}</p>
                            </v-col>
                            <v-spacer/>
                            <v-col v-if="ct.id === selected_regional.id"  class="pa-1 text-right">
                            
                                <v-icon :class="{'red--text text--lighten-2':ct.id === selected_regional.id}">fa-check</v-icon>
                            
                            </v-col>
                            </v-row>
                        </v-card>-->
                        </v-col>
                    </v-row>
             
                </v-card-text>
            </v-card>
        </v-dialog>
      <v-card  width="100%" flat style="cursor:pointer;margin-top:105px" class="mx-auto ml-2 mr-2" >
    <!--start banner home service-->
    <v-row align="center" class="pl-0 pr-2 flex-wrap no-gutters">
      <v-col cols="">
        <v-card class="ml-0" tile elevation="0">
        <v-img
             
                      class="white--text"
                      height="auto"
                    width="auto"
                      :src="banner_hs"
         @click="goToSetPlace()"
                    >
             
                      </v-img>
        </v-card>
      </v-col>
    </v-row>
    <!--end banner home service-->
        <v-row align="center" class="ml-2 flex-wrap no-gutters">
          <v-col  @click="dialog_regional = true" class="pa-2" cols="10">
            <p style="font-size:18px" class="mb-0 poppins-light">Anda berada di :</p>
            <p style="color:#68a036;font-size:22px" class="mb-0 poppins-bold">{{selected_regional.name}}</p>
          </v-col>
          <v-col @click="dialog_regional = true" class="text-right pr-6" cols="2">
            <v-icon>fa-edit</v-icon>
          </v-col>
        </v-row>
      </v-card>
                  <v-divider></v-divider>
                  <v-sheet id="scrolling-techniques-7"
                    class="overflow-y-auto mt-0 pt-0"
                    :style="{'max-height':typeof this.$store.state.choosebranch.header == 'undefined'?'600px':'680px'}">
                    <v-container style="margin-bottom:105px;min-height: 450px;">
                  <div style="font-weight:800;font-size:16px;color:#68a036" class="mt-0 ml-2 poppins-bold ma-2 mb-5">PILIH LOKASI LAYANAN</div>

                  <v-skeleton-loader
                      v-if="loading_content"
                      v-for="n in 2"
                      min-width="330"
                      :key="n"
                      type="card"
                    ></v-skeleton-loader>

                  <v-card
                    class="ml-2 mx-auto mr-1 mb-2" 
                    style="cursor:pointer"
                    v-for="(br,n) in branches"
                    v-if="!loading_content"
                    :key="n"
                  >
                    <v-img
             
                      class="white--text align-end"
                      height="200px"
                     gradient="to bottom right,rgba(147,142,142,.42), rgba(100,115,201,.33) "
                      :src="br.photox"
                      @click="goToBranch(br)"
                    >
                    <div class="fill-height bottom-gradient"></div>
                      <v-card-title style="background-color: #67a0366e" @click="goToBranch(br)"><v-icon v-if="!_.isEmpty(selected_branch) && br.id === selected_branch.id" dark class="pr-2" >fa-check</v-icon> <span class="poppins-bold">{{br.name}}</span></v-card-title>
                    </v-img>

                    <v-card-subtitle @click="goToBranch(br)" class="caption pb-0">
                      <div style="color:#68a036" class="poppins-bold">ESTIMASI JARAK : {{br.distance}} KM</div>
                    </v-card-subtitle>

                    <v-card-text @click="goToBranch(br)" class="text--primary pb-2">
                      <p class="poppins-light">{{br.address}}</p>
                    </v-card-text>

                  </v-card>
            
                 
                
                    </v-container>
          
                </v-sheet>
                
  </v-container>
</template>
<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins');
.poppins-bold{
  font-family: 'Poppins', sans-serif;
  font-weight:800;
}
.semi-poppins{
  font-family: 'Poppins', sans-serif;
  font-weight:600;
}
.poppins-light{
  font-family: 'Poppins', sans-serif;
  font-weight:300;
}
.bottom-gradient {
    background-image: repeating-linear-gradient(-45deg,
                        rgba(255,0,0,.25),
                        rgba(255,0,0,.25) 5px,
                        rgba(0,0,255,.25) 5px,
                        rgba(0,0,255,.25) 10px)
  }
  

</style>
<script>
  export default {
    name: 'xchoosebranch',
    mounted(){
      console.log(this.banner_hs)
       this.$store.commit("beranda/update_order_hs",false)
       this.$store.commit("choosebranch/update_selected_hs_address",{})
       
        this.$store.commit("choosebranch/update_header",this.$route.params.header)
      //if(_.isEmpty(this.selected_branch))
        this.trackPosition()
    },
    computed:{
        banner_hs(){
          return window.hostx+"/one_api_coba/test/test_image/banner_hs"
        },
        loading_content:{
          get() {
              return this.$store.state.choosebranch.loading_content
                  
          },
          set(val) {
                this.$store.commit("choosebranch/update_loading_content",val)
          }
        },
        header:{
          get() {
            if(this.$store.state.choosebranch.header)
              parseInt(this.$store.state.choosebranch.header)
            else return 1
                  
          },
          set(val) {
                this.$store.commit("choosebranch/update_header",val)
          }
        },
        regionals:{
          get() {
              return this.$store.state.choosebranch.regionals
                  
          },
          set(val) {
                this.$store.commit("choosebranch/update_regionals",val)
          }
        },
        selected_regional:{
          get() {
              return this.$store.state.choosebranch.selected_regional
                  
          },
          set(val) {
                this.$store.commit("choosebranch/update_selected_regional",val)
          }
        },
        branches:{
          get() {
              return this.$store.state.choosebranch.branches
                  
          },
          set(val) {
                this.$store.commit("choosebranch/update_branches",val)
          }
        },
        selected_branch:{
          get() {
              return this.$store.state.choosebranch.selected_branch
                  
          },
          set(val) {
                this.$store.commit("choosebranch/update_selected_branch",val)
          }
        },
        dialog_regional:{
          get() {
              return this.$store.state.choosebranch.dialog_regional
                  
          },
          set(val) {
                this.$store.commit("choosebranch/update_dialog_regional",val)
          }
        },
        loading:{
                get() {
                    return this.$store.state.choosebranch.loading
                        
                },
                set(val) {
                        this.$store.commit("choosebranch/update_loading",val)
                }
            },
    },
     methods:{
       goSearch(){
                var search = this.search_regional
                
                    this.loading = true
                    this.$store.dispatch("choosebranch/searchRegional",{search:search})
                
            },
       trackPosition() {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.successPosition, this.failurePosition, {
              enableHighAccuracy: true,
              timeout: 15000,
              maximumAge: 0,
            })
          } else {
            console.log(`Browser doesn't support Geolocation`)
          }
        },
        successPosition: function(position) {
          //console.log(position)
          let prm = {lat: position.coords.latitude, lng: position.coords.longitude}
          this.$store.commit("beranda/update_lat",position.coords.latitude)
          this.$store.commit("beranda/update_lng",position.coords.longitude)
          this.$store.commit("choosebranch/update_loading_content",true)
          this.$store.dispatch("choosebranch/getregionals_nearest",prm)
        },
        failurePosition: function(err) {
          let prm = {lat: 0, lng: 0}
          this.$store.commit("beranda/update_lat",0)
          this.$store.commit("beranda/update_lng",0)
          //this.$store.commit("beranda/update_dialog_loading",true)
          this.$store.commit("choosebranch/update_loading_content",true)
          this.$store.dispatch("choosebranch/getregionals_nearest_without_gps",{})
          console.log('Error Code: ' + err.code + ' Error Message: ' + err.message)
        },
        setRegional(regional){
          this.selected_regional = regional
          regional.lat = this.$store.state.beranda.lat
          regional.lng = this.$store.state.beranda.lng
          //this.$store.commit("beranda/update_dialog_loading",true)
          this.$store.commit("choosebranch/update_loading_content",true)
          this.$store.dispatch("choosebranch/getbranches",regional)
        },
        goToBranch(branch){
          this.$store.commit("choosebranch/update_header",1)
          //var localstorage = JSON.parse(localStorage.getItem("oneState"))
          if(this.selected_branch){
            var selbrach = this.selected_branch
            if(parseInt(selbrach.id) !== parseInt(branch.id)){
              this.$store.commit("test/update_cart",[])
              this.$store.commit("test/update_selected_tests",[])
              this.$store.commit("test/update_select_test",{})
            }
          }
          
          
          this.selected_branch = branch
          var xstate = {}
          xstate.beranda = this.$store.state.beranda
          xstate.order = this.$store.state.order
          xstate.choosebranch = this.$store.state.choosebranch
          xstate.test = this.$store.state.test
          localStorage.setItem('oneState', JSON.stringify(xstate));
          //this.$router.push({ path: `/pilihpemeriksaan/id/${branch.id}/name/${branch.name}` }) 
         this.$router.push({name:'xselbranch'})
        },
        goToSetPlace(){
          this.$store.commit("beranda/update_order_hs",true)
          this.$store.commit("test/update_cart",[])
          this.$store.commit("test/update_selected_tests",[])
           this.selected_branch = {}
            var xstate = {}
          xstate.beranda = this.$store.state.beranda
          xstate.order = this.$store.state.order
          xstate.choosebranch = this.$store.state.choosebranch
          localStorage.setItem('oneState', JSON.stringify(xstate));
          //this.$router.push({name:'xsetplacehs'})
          this.$store.dispatch("choosebranch/checkSavedAddress",{})
        }
    },
    data: () => ({
      search_regional:'',
      dialog_error:false,
      msg_error:'',
      dialog_branch:false,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      show: false
    }),
  }
</script>
