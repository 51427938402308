<template>
  <div>
      <v-app-bar
      color="white"
      fixed
      elevation="1"
      height="59px"
    >
        <v-list-item class="mx-auto pa-0">
        <v-list-item-avatar>
            <v-icon style="cursor:pointer" @click="goBack()">mdi-arrow-left</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
            <v-list-item-subtitle class="font-weight-bold title">
            <v-row no-gutters>
                <v-col cols="8">
                Detail Pesanan
                </v-col>
            </v-row>
            </v-list-item-subtitle>
        </v-list-item-content>
        </v-list-item>
    </v-app-bar>
  <v-card
    color="#68a036"
    class="mx-auto mt-16 mb-2"
    tile
  >
    <v-card-text>
                <v-row v-for="(order,k_i) in data_order" :key="k_i">
                    <v-col cols="12">
                        <v-card elevation="10">
                            
                            <v-list-item three-line>
                                <v-list-item-content>
                                  <p class="caption mb-1">Kode Booking</p>
                                  <kbd class="mb-2">
                                     {{order.qrcode}}
                                  </kbd>
                                  <v-list-item-subtitle><p class="mb-0 caption">{{order.order_date}} {{order.order_time}}</p></v-list-item-subtitle>
                                  <v-list-item-title class="title mb-1">
                                    {{order.patient_name}}
                                  </v-list-item-title>
                                  <v-list-item-subtitle>{{order.id_number}}</v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-avatar
                                  tile
                                  size="80"
                                  color="grey"
                                ><QRCanvas :options="{cellSize: 4,correctLevel: 'H',data: order.qrcode}"/></v-list-item-avatar>
                              </v-list-item>

                              <v-card-actions>
                                <v-btn
                                  text
                                  @click="openDetail(order,k_i)"
                                >
                                  <p v-if="order.xshow == 'N'" class="mb-0">LIHAT DETAIL</p>
                                    
                                </v-btn>
                              </v-card-actions>
                            
                        </v-card>
                    </v-col>
                </v-row>
                
            </v-card-text>
  </v-card>
  
  </div>
                
</template>
<style>


.box-center{
  position: fixed;
  left: 50%;
  bottom: 0px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}
@media only screen and (min-device-width : 1024px){
/* Styles */
/* width */
::-webkit-scrollbar {
  height: 8px;              /* height of horizontal scrollbar ← You're missing this */
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
}

</style>
<script>
import { QRCanvas } from 'qrcanvas-vue';

  export default {
    name: 'xdetailstatusresult',
    components: {
      QRCanvas
    },
    mounted(){
      this.$store.commit("beranda/update_dialog_loading",true)
      this.$store.dispatch("order/getDataOrderList",this.$route.params)

      if(this.$vuetify.breakpoint.name == 'xs'){
        this.window_width = window.innerWidth
      }
      else
        this.window_width = '560px'

    },
    computed:{
      list_header:{
        get() {
            return this.$store.state.order.list_header
                
        },
        set(val) {
              this.$store.commit("order/update_list_header",val)
        }
      },
      list_payment:{
        get() {
            return this.$store.state.order.list_payment
                
        },
        set(val) {
              this.$store.commit("order/update_list_payment",val)
        }
      },
      user:{
        get() {
            return this.$store.state.beranda.user
                
        },
        set(val) {
              this.$store.commit("beranda/update_user",val)
        }
      },
      opt_qrcode:{
        get() {
            return this.$store.state.order.opt_qrcode
                
        },
        set(val) {
              this.$store.commit("order/update_opt_qrcode",val)
        }
      },
      data_order:{
        get() {
            return this.$store.state.order.data_order
                
        },
        set(val) {
              this.$store.commit("order/update_data_order",val)
        }
      },
      lat:{
        get() {
            return this.$store.state.beranda.lat
                
        },
        set(val) {
              this.$store.commit("beranda/update_lat",val)
        }
      },
      lng:{
        get() {
            return this.$store.state.beranda.lng
                
        },
        set(val) {
              this.$store.commit("beranda/update_lng",val)
        }
      },
      window_width:{
        get() {
            return this.$store.state.beranda.window_width
                
        },
        set(val) {
              this.$store.commit("beranda/update_window_width",val)
        }
      },
      date_myformat(){
        if(!_.isEmpty(this.$store.state.order.data_order)){
          var d = new Date(this.$store.state.order.data_order.order_date);
        var weekday = new Array(7);
        weekday[0] = "Minggu";
        weekday[1] = "Senin";
        weekday[2] = "Selasa";
        weekday[3] = "Rabu";
        weekday[4] = "Kamis";
        weekday[5] = "Jumat";
        weekday[6] = "Sabtu";

        var n = weekday[d.getDay()];
        //console.log(this.localstorage.order.selected_date)
        return n+', '+this.$moment(d).format('DD-MM-YYYY') 
        }
        else
        return 'dd-mm-yyyy'
        
      }
    },
    methods:{
      goBayar(){
        if(this.list_header.payment_status === 'N')
          this.$router.push({ path: `/order/id/${this.list_header.id}`})
        else
          this.$router.push({ path: `/payment/id/${this.list_header.order_number}/code/${this.list_payment.payment_code}`})
      },
      openDetail(order,i){
            this.$router.push({ path: `/timelinestatusresult/id/${order.id}`})
     
          },
        goBack(){
          this.$router.push({name:'xstatusresult'})
        },
        getWIdth(){
          switch (this.$vuetify.breakpoint.name) {
            case 'xs': return 320
            case 'sm': return 325
            case 'md': return 363
            case 'lg': return 363
          }
        }
    },
    data: () => ({
      show:true
    }),
  }
</script>
