<template>
  <div style="overflow-x:hidden;overflow-y:hidden">
  <v-dialog
      v-model="success_delete_account"
      persistent
    >
      
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          BERHASIL
        </v-card-title>

        <v-card-text>
          <p class="mt-2 mb-0">Akun anda berhasil dihapus</p>
          
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            @click="goLogout()"
          >
            Tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  <v-dialog
      v-model="delete_akun_otp"
      persistent

    >
     
      <v-card>
        <v-card-text>
          <div v-if="show_methode">
            <v-card flat="flat" class="mx-auto mt-16" width="100px">
                <v-icon class="pa-4 pb-1" style="font-size:62px">mdi-cellphone-key</v-icon>
            </v-card>
            <v-row class="mt-1  pt-1 mx-auto">
                <v-col>
                    <v-card flat="flat" class="mx-auto">
                        <v-row>
                            <v-col class="text-center mb-5" cols="12">
                                <!--<p class="body-1 mb-1 font-weight-bold">PILIH METODE PENGIRIMAN OTP</p>-->
                                <p class="body-2 mb-1">(<span >ONE TIME PASSWORD</span>)</p>
                            </v-col>
                            <!--<v-col class="text-center" cols="12">
                                <v-btn rounded @click="getOTP('sms')" dark="dark" color="blue lighten-1">
                                    <v-icon class="mr-2">mdi-email</v-icon>
                                    SMS(
                                    <span >{{user.username}}</span>
                                    )</v-btn>
                            </v-col>-->
                            <v-col class="text-center" cols="12">
                                <v-btn rounded @click="getOTP('wa')" dark="dark" color="green">
                                    <v-icon class="mr-2">mdi-whatsapp</v-icon>
                                    WA (
                                    <span>{{user.username}}</span>
                                    )</v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </div>
          <div v-if="show_daftar_otp">
         <v-row justify="center" class="mx-auto mt-15">
                <v-col>
                    <v-card flat="flat">
                        <v-row class="mb-5">
                            <v-col class="text-center" cols="12">
                                <p class="mb-5">
                                    <v-icon>fa-mobile-alt</v-icon>
                                </p>
                                <p class="h4">Masukkan Kode Verifikasi</p>
                                <p class="body-2">Kode verifikasi telah dikirim ke</p>
                                <p class="body-2">
                                    <span v-if="act === 'login'">{{login_hp}}</span>
                                    <span v-else="v-else">{{daftar_hp}}</span>
                                </p>
                            </v-col>
                        </v-row>
                        <v-row class="mb-5">
                            <v-col class="mx-auto" cols="3">
                                <v-text-field
                                    color="red"
                                    class="ma-0"
                                    type="number"
                                    @keyup="keyupOTP"
                                    hide-details="hide-details"></v-text-field>
                                <p class="mt-2 body-2 text-center">Kode OTP</p>
                            </v-col>
                        </v-row>
                        <v-row class="mb-3">
                            <v-col class="mx-auto" cols="12">
                                <p v-if="!show_resend_otp" class="body-2 text-center">Mohon tunggu dalam
                                    {{otp_counting}}
                                    detik untuk kirim ulang</p>
                                <p v-if="show_resend_otp" class="body-2 text-center">
                                    <v-btn small="small" @click="showMethode()" color="secondary" dark="dark">
                                        Kirim Ulang OTP
                                    </v-btn>
                                </p>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            </div>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-1"
            text
            @click="delete_akun_otp = false"
          >
            tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  
   <v-dialog
      v-model="dialog_delete_account"
      persistent

    >
     
      <v-card>
        <v-card-title class="red--text text-h5">
          Apakah Anda yakin akan menghapus akun? 
        </v-card-title>
        <v-card-text>
         <p>Bila ya, kami akan menghapus alamat email, kata sandi, dan nomor telepon Anda.
Anda tidak akan dapat lagi mengakses data hasil pemeriksaan dan pesanan anda.</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-1"
            text
            @click="dialog_delete_account = false"
          >
            Batal
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="delete_account_now()"
          >
            Lanjutkan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
       <v-card
            class="mx-auto"
           
        >
           <v-app-bar
            color="white"
            fixed
            elevation="1"
            height="59px"
            class="pl-0 ml-0"
            >
                <v-list-item  two-line class="mx-auto ml-0 pa-0">
       
                <v-list-item-content class="pl-0 ml-0 ">
                    <v-list-item-subtitle class="pl-0 ml-0 font-weight-bold title">
                    <v-row no-gutters>
                        <v-col  cols="12">
                            <v-list-item class="pl-0 ml-0 ">
                    <v-list-item-avatar class="pl-0 ml-0 ">
                        <v-img
                            class="mx-auto pa-0 mb-2"
                            :src="require('../assets/avatar.png')"
                            width="50"
                            height="40"
                        ></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                    <v-list-item-title>{{user.username}}</v-list-item-title>
         
                    
                    </v-list-item-content>
                </v-list-item>
                        </v-col>
                    </v-row>
                    </v-list-item-subtitle>
                </v-list-item-content>
                </v-list-item>
            </v-app-bar>

            <v-list
            subheader
            class="mt-16"
            >
                
                <v-subheader>Menu Lainnya</v-subheader>

                <v-list-item class="mb-2" @click="goToStatusResult()">
                    <v-list-item-content>
                    <v-list-item-title><v-icon class="pr-2">fa-file-medical-alt</v-icon>  Status Hasil</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider/>
                <v-list-item class="mb-2"  @click="goToListPatient()">
                    <v-list-item-content>
                    <v-list-item-title><v-icon class="pr-2">fa-address-book</v-icon>  Daftar Pasien</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider/>
                <v-list-item class="mb-2"  @click="goToListAddressHS()">
                    <v-list-item-content>
                    <v-list-item-title><v-icon class="pr-2">fa-address-book</v-icon>  Daftar Alamat HS</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider/>
                 <v-list-item class="mb-2"  @click="goDeleteAccount()">
                    <v-list-item-content>
                    <v-list-item-title><v-icon class="pr-2">fa-user</v-icon>  Hapus Akun</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider/>
                <v-list-item class="mt-3 mb-2"  @click="logout()">
                    <v-list-item-content>
                    <v-list-item-title class="font-weight-bold green--text text--darken-2"><v-icon color="green ligthen-1" class="pr-2">fa-sign-out-alt</v-icon>  Keluar</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card>
        <!--<v-bottom-navigation 
            background-color="red lighten-1"
            color="yellow lighten-3"
            dark elevation="1" grow fixed>
            <v-btn  @click="goBack()">
                <span>BERANDA</span>
                <v-icon  class="pb-1">fa-home</v-icon>
            </v-btn>
            <v-btn  @click="listorder()" v-if="isLogin">
                <span>PESANAN</span>
                <v-icon  class="pb-1">fa-calendar-alt</v-icon>
            </v-btn>
            <v-btn @click="login('daftar')" v-if="!isLogin">
                <span>DAFTAR</span>
                <v-icon class="pb-1">fa-user-plus</v-icon>
            </v-btn>
            <v-btn @click="login()" v-if="!isLogin">
                <span>LOGIN</span>
                <v-icon class="pb-1">fa-sign-in-alt</v-icon>
            </v-btn>
            <v-btn @click="menu()" v-if="isLogin">
                <span>LAINNYA</span>
                <v-icon color="warning lighten-3" class="pb-1">fa-address-card</v-icon>
            </v-btn>
    </v-bottom-navigation>-->
     <v-bottom-navigation height="80"
                    background-color="white"
                    
                   elevation="1" grow fixed>
                  
                    <v-row>
                      <v-col  class="text-center" cols="4" >
                        <v-img @click="goBack()"
                          class="mx-auto"
                          :lazy-src="require('../assets/icon-home-grey.png')"
                          :src="require('../assets/icon-home-grey.png')"
                          width="40"
                          height="40"
                        ></v-img>
                        <p style="font-size:14px;" class="poppins-light mx-auto ">Beranda</p>
                      </v-col>
                      <v-col v-if="!isLogin"  class="text-center" cols="4" >
                        <v-img  @click="login('daftar')"
                          class="mx-auto"
                          :lazy-src="require('../assets/icon-daftar-grey.png')"
                          :src="require('../assets/icon-daftar-grey.png')"
                          width="40"
                          height="40"
                        ></v-img>
                        <p style="font-size:14px" class="poppins-light mx-auto ">Daftar</p>
                      </v-col>
                      <v-col v-if="!isLogin" class="text-center" cols="4" >
                        <v-img  @click="login()"
                          class="mx-auto"
                          :lazy-src="require('../assets/icon-login-grey.png')"
                          :src="require('../assets/icon-login-grey.png')"
                          width="40"
                          height="40"
                        ></v-img>
                        <p style="font-size:14px" class="poppins-light mx-auto ">Login</p>
                      </v-col>
                      <v-col v-if="isLogin"  class="text-center" cols="4" >
                        <v-img  @click="listorder()"
                          class="mx-auto"
                          :lazy-src="require('../assets/icon_pesanan_black.png')"
                          :src="require('../assets/icon_pesanan_black.png')"
                          width="40"
                          height="40"
                        ></v-img>
                        <p style="font-size:14px;" class="poppins-light mx-auto ">Pesanan</p>
                      </v-col>
                  
                  <v-col v-if="isLogin"  class="text-center" cols="4" >
                        <v-img  @click="menu()"
                          class="mx-auto"
                          :lazy-src="require('../assets/icon_akunred.png')"
                          :src="require('../assets/icon_akunred.png')"
                          width="40"
                          height="40"
                        ></v-img>
                        <p style="font-size:14px;color:#52b356" class="font-weight-bold poppins-light mx-auto ">Akun</p>
                      </v-col>
                  
                    </v-row>
                        
                </v-bottom-navigation> 
</div>
                
</template>
<style>


.box-center{
  position: fixed;
  left: 50%;
  bottom: 0px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}
@media only screen and (min-device-width : 1024px){
/* Styles */
/* width */
::-webkit-scrollbar {
  height: 8px;              /* height of horizontal scrollbar ← You're missing this */
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
}

</style>
<script>
import axios from 'axios';
import InfiniteLoading from 'vue-infinite-loading';

  export default {
    name: 'xmenu',
    components: {
      InfiniteLoading
    },
    mounted(){
      //this.orders = []
      this.$store.commit("beranda/update_dialog_loading",true)
      this.$store.dispatch("test/getPointsUser",{})
      this.$store.commit("order/update_selected_time",{})
      if (localStorage.getItem("user")){
        this.user = JSON.parse(localStorage.getItem("user"))
        this.isLogin = true
        this.orders = []
        this.page = 1
         if(this.orders.length == 0){
           this.$store.commit("order/update_start_date",this.$moment().clone().startOf('month').format('YYYY-MM-DD'))
          this.$store.commit("order/update_end_date",this.$moment().clone().endOf('month').format('YYYY-MM-DD'))
          this.range_date = [this.$moment().clone().startOf('month').format('YYYY-MM-DD'),this.$moment().clone().endOf('month').format('YYYY-MM-DD')]
         }
      }
      else
        this.isLogin = false

        console.log(this.$moment().clone().startOf('month').format('YYYY-MM-DD'))
        
      //this.getOrders()

      if(this.$vuetify.breakpoint.name == 'xs'){
        this.window_width = window.innerWidth
      }
      else
        this.window_width = '560px'

    },
    computed:{
      otp: {
                get() {
                    return this.$store.state.order.otp

                },
                set(val) {
                    this
                        .$store
                        .commit("order/update_otp", val)
                }
            },
      otp_counting: {
                get() {
                    return this.$store.state.order.otp_counting

                },
                set(val) {
                    this
                        .$store
                        .commit("order/update_otp_counting", val)
                }
            },
      delete_akun_otp:{
        get() {
            return this.$store.state.order.delete_akun_otp
                
        },
        set(val) {
              this.$store.commit("order/update_delete_akun_otp",val)
        }
      },
      dialog_delete_account:{
        get() {
            return this.$store.state.order.dialog_delete_account
                
        },
        set(val) {
              this.$store.commit("order/update_dialog_delete_account",val)
        }
      },
show_resend_otp:{
        get() {
            return this.$store.state.order.show_resend_otp
                
        },
        set(val) {
              this.$store.commit("order/update_show_resend_otp",val)
        }
      },
      show_daftar_otp:{
        get() {
            return this.$store.state.order.show_daftar_otp
                
        },
        set(val) {
              this.$store.commit("order/update_show_daftar_otp",val)
        }
      },
      show_methode:{
        get() {
            return this.$store.state.order.show_methode
                
        },
        set(val) {
              this.$store.commit("order/update_show_methode",val)
        }
      },
      delete_akun_otp:{
        get() {
            return this.$store.state.order.delete_akun_otp
                
        },
        set(val) {
              this.$store.commit("order/update_delete_akun_otp",val)
        }
      },
      points:{
        get() {
            return this.$store.state.test.points
                
        },
        set(val) {
              this.$store.commit("test/update_points",val)
        }
      },
        dateRangeText () {
            var min = ''
            var max = ''
        if(this.$moment(this.range_date[0]) < this.$moment(this.range_date[1])){
            min = this.$moment(this.range_date[0])
            max = this.$moment(this.range_date[1])
        }
        else{
            max = this.$moment(this.range_date[0])
            min = this.$moment(this.range_date[1])
        }

        var start_date = this.$moment(min).format("DD-MM-YYYY")
        var end_date = this.$moment(max).format("DD-MM-YYYY")
        this.$store.commit("order/update_start_date",start_date)
        this.$store.commit("order/update_end_date",end_date)
        return start_date+" ~ "+end_date
      },
        range_date:{
        get() {
            return this.$store.state.order.range_date
                
        },
        set(val) {
              this.$store.commit("order/update_range_date",val)
        }
      },
      page:{
        get() {
            return this.$store.state.order.page
                
        },
        set(val) {
              this.$store.commit("order/update_page",val)
        }
      },
      selected_branch:{
        get() {
            return this.$store.state.choosebranch.selected_branch
                
        },
        set(val) {
              this.$store.commit("choosebranch/update_selected_branch",val)
        }
      },
      lat:{
        get() {
            return this.$store.state.beranda.lat
                
        },
        set(val) {
              this.$store.commit("beranda/update_lat",val)
        }
      },
      lng:{
        get() {
            return this.$store.state.beranda.lng
                
        },
        set(val) {
              this.$store.commit("beranda/update_lng",val)
        }
      },
      window_width:{
        get() {
            return this.$store.state.beranda.window_width
                
        },
        set(val) {
              this.$store.commit("beranda/update_window_width",val)
        }
      },
      success_delete_account:{
        get() {
            return this.$store.state.order.success_delete_account
                
        },
        set(val) {
              this.$store.commit("order/update_success_delete_account",val)
        }
      },
    },
    methods:{
      goLogout(){
        this.success_delete_account = false
        var user =  JSON.parse(localStorage.getItem("user"))
          user.lat = this.$store.state.beranda.lat
          user.lng = this.$store.state.beranda.lng
          this.$store.commit("beranda/update_dialog_loading",true)
          this.$store.dispatch("login/logout", user)
      },
      getOTP(methode) {
                this.show_methode = false
                let hp = this.user.username
                    this
                    .$store
                    .commit("beranda/update_dialog_loading", true)
                this
                    .$store
                    .dispatch("order/getOTP", {
                        act: methode,
                        hp: hp
                    })
            },
            keyupOTP(event) {
                const value = event.target.value
                this.otp = value
                let hp = this.user.username
                if (value.length == 4) {
                    this
                        .$store
                        .commit("beranda/update_dialog_loading", true)
                    this
                        .$store
                        .dispatch("order/checkOTP", {
                            otp: this.otp,
                            hp: hp
                        })
                }
            },
      delete_account_now(){
        this.dialog_delete_account = false
this.delete_akun_otp = true
this.show_methode = true
        //var user = this.user
         // this.$store.dispatch("login/delete_account", user)
      },
        goDeleteAccount(){
          this.dialog_delete_account = true
        },
        goToStatusResult(){
             this.$router.push({name:'xstatusresult'})
        },
        goToListPatient(){
           this.$router.push({name:'xlistpatient'})
        },
        goToListAddressHS(){
           this.$router.push({name:'xlistaddresshs'})
        },
        goBack(){
            this.$router.push({name:'xberanda'})
        },
        goToDetail(id){
            //var id = 11
            this.$router.push({ path: `/detailstatusresult/id/${id}` })
        },
        login(act){
          if(act === 'daftar'){
            this.$router.push({name:'xlogin',params:{act:act}})
          }
          else
          this.$router.push({name:'xlogin'})
        },
        getWIdth(){
          switch (this.$vuetify.breakpoint.name) {
            case 'xs': return 320
            case 'sm': return 325
            case 'md': return 363
            case 'lg': return 363
          }
        },
        logout(){
          var user =  JSON.parse(localStorage.getItem("user"))
          user.lat = this.$store.state.beranda.lat
          user.lng = this.$store.state.beranda.lng
          this.$store.commit("beranda/update_dialog_loading",true)
          this.$store.dispatch("login/logout", user)
        },
        menu(){
          this.$router.push({name:'xmenu'})
        },
        listorder(){
          this.$router.push({ name: 'xlistorder'})
        },
        getOrders(){
            this.dialog_daterange = false
            this.$store.dispatch("order/getOrders", {start_date:this.$store.state.order.start_date,end_date:this.$store.state.order.end_date})
        },
        openCalendar(){
            this.dialog_daterange = true
        },
        changeType() {
          this.page = 1;
          this.orders = [];
          this.infiniteId += 1;
          this.dialog_daterange = false
            },
            infiniteHandler($state) {
              var user = JSON.parse(localStorage.getItem("user"))

          axios.get("https://regonline.pramita.co.id/one-api/mobile/order/getOrders", {
            params: {
              current_page: this.page,
              start_date:this.$store.state.order.start_date,
              end_date:this.$store.state.order.end_date,
              token : user.token
            },
          }).then(({ data }) => {
            if (data.data.length) {
              this.page += 1;
              this.orders.push(...data.data);
              $state.loaded();
            } else {
              $state.complete();
            }
          });
        }
    },
    data: () => ({
        isLogin:'N',
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      user:{}
    }),
  }
</script>
